import React from "react"

/* Header */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  PhoneIcon,
  UserGroupIcon,
  XIcon,
  MailIcon,
} from '@heroicons/react/outline'
import { UserIcon, ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { StaticImage } from "gatsby-plugin-image"


/*
Header
*/

// PRESTATIONS
const presentations = [
  {
    name: 'Qui sommes nous',
    description: '',
    href: '/nous-connaitre/',
    icon: ChevronRightIcon,
  },
  {
    name: 'Historique',
    description: '',
    href: '/nous-connaitre/historique/',
    icon: ChevronRightIcon,
  },
  {
    name: "L'Equipe",
    description: '',
    href: '/nous-connaitre/equipe/',
    icon: ChevronRightIcon,
  },
  { 
    name: 'Nos atouts et nos valeurs', 
    description: "", 
    href: '/nous-connaitre/atouts/', 
    icon: ChevronRightIcon 
  },
  {
    name: 'Visio et application',
    description: "",
    href: '/nous-connaitre/visio/',
    icon: ChevronRightIcon,
  },
  {
    name: 'Garanties et assurances',
    description: "",
    href: '/nous-connaitre/garanties/',
    icon: ChevronRightIcon,
  },
  {
    name: 'Recrutement',
    description: "",
    href: '/nous-connaitre/recrutement/',
    icon: ChevronRightIcon,
  },
  {
    name: 'Mentions légales',
    description: "",
    href: '/nous-connaitre/legal/mentions-legales/',
    icon: ChevronRightIcon,
  },
]

const callsToAction = [
  { name: 'Nous rejoindre', href: '/recrutement/contact/', icon: UserGroupIcon },
  { name: 'Contact', href: '/devis/contact/', icon: PhoneIcon },
]

// SITUATION
const situations = [
  {
    name: 'Votre situation',
    description: '',
    href: '/situation/',
    icon: ChevronRightIcon,
  },
  {
    name: 'Déjà client',
    description: '',
    href: '/situation/qui/client/',
    icon: ChevronRightIcon,
  },
  {
    name: "Locataire",
    description: '',
    href: '/situation/qui/locataire/',
    icon: ChevronRightIcon,
  },
  { 
    name: 'Propriétaire', 
    description: "", 
    href: '/situation/qui/proprietaire/', 
    icon: ChevronRightIcon 
  },
  {
    name: 'Régie',
    description: "",
    href: '/situation/qui/regie/',
    icon: ChevronRightIcon,
  },
  {
    name: 'Dépannage plomberie',
    description: "",
    href: '/situation/motifs-intervention/depannage-plomberie/',
    icon: ChevronRightIcon,
  },
  {
    name: 'Petit travaux et aménagement plomberie',
    description: "",
    href: '/situation/motifs-intervention/petit-travaux-plomberie/',
    icon: ChevronRightIcon,
  },
]
const callsToAction2 = [
  { name: 'Créer une demande', href: '/devis/contact/', icon: MailIcon },
]


// PLOMBIER
const plombiers = [
  {
    name: 'Trouvez un plomblier',
    description: '',
    href: '/reparations-plomberie/',
    icon: ChevronRightIcon,
  },
  {
    name: 'Les réparations courantes',
    description: '',
    href: '/reparations-plomberie/courantes/',
    icon: ChevronRightIcon,
  },
  {
    name: "Réparation robinetterie",
    description: '',
    href: '/reparations-plomberie/robinetterie/',
    icon: ChevronRightIcon,
  },
  { 
    name: 'Réparation canalisations', 
    description: "", 
    href: '/reparations-plomberie/canalisations/', 
    icon: ChevronRightIcon 
  },
  {
    name: 'Réparation sanitaires',
    description: "",
    href: '/reparations-plomberie/sanitaires/',
    icon: ChevronRightIcon,
  },
  {
    name: 'Réparation eau chaude sanitaire',
    description: "",
    href: '/reparations-plomberie/eau-chaude-sanitaire/',
    icon: ChevronRightIcon,
  },
]
const callsToAction3 = [
  { name: 'Contactez un plombier', href: '/devis/contact/', icon: PhoneIcon },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function KdpHeader() {
  return (
    <Popover className="relative bg-white">
      
      <div className="absolute inset-0 shadow z-20 pointer-events-none" aria-hidden="true" />
      <div className="relative z-20">
        <div className="max-w-7xl mx-auto flex justify-between items-center px-4 py-4 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
          <div>
            <a href="/" className="flex">
              <span className="sr-only">blicko</span>
              <StaticImage 
                    src="../../images/logo__blicko.png"
                    alt="blicko"
                    width={140}
                />
            </a>
          </div>

          <div className="ml-20 -mr-2 -my-2 text-gray-400 md:hidden">
            <a href="https://app.blicko.fr/login" target="_blank" rel="noreferrer" className="">
              <UserIcon className="h-6 w-6" aria-hidden="true" />
            </a>
          </div>

          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#3333e6]">
              <span className="sr-only">Ouvrir le menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
            <Popover.Group as="nav" className="flex space-x-4">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3333e6]'
                    )}
                  >
                    <span>Qui sommes nous</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-30 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {presentations.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon className="flex-shrink-0 h-6 w-6 text-[#3333e6]" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                        <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          {callsToAction.map((item) => (
                            <div key={item.name} className="flow-root">
                              <a
                                href={item.href}
                                className="-m-3 p-3 flex items-center rounded-md text-base font-medium bg-orange-600 text-white hover:bg-orange-700"
                              >
                                <item.icon className="flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
                                <span className="ml-3">{item.name}</span>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3333e6]'
                    )}
                  >
                    <span>Votre situation</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {situations.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon className="flex-shrink-0 h-6 w-6 text-[#3333e6]" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                        <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          {callsToAction2.map((item) => (
                            <div key={item.name} className="flow-root">
                              <a
                                href={item.href}
                                className="-m-3 p-3 flex items-center rounded-md text-base font-medium bg-orange-600 text-white hover:bg-orange-700"
                              >
                                <item.icon className="flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
                                <span className="ml-3">{item.name}</span>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3333e6]'
                    )}
                  >
                    <span>Trouvez un plombier</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {plombiers.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon className="flex-shrink-0 h-6 w-6 text-[#3333e6]" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                        <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          {callsToAction3.map((item) => (
                            <div key={item.name} className="flow-root">
                              <a
                                href={item.href}
                                className="-m-3 p-3 flex items-center rounded-md text-base font-medium bg-orange-600 text-white hover:bg-orange-700"
                              >
                                <item.icon className="flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
                                <span className="ml-3">{item.name}</span>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            </Popover.Group>
            <div className="flex items-center md:ml-12">
              <a href="https://app.blicko.fr/login" target="_blank" rel="noreferrer" className="text-base inline-flex font-medium text-gray-500 hover:text-gray-900 px-4 py-2 rounded-md shadow-sm border border-[#3333e6]">
                <UserIcon className="-mr-1 h-5 w-5 text-gray-500" aria-hidden="true" />&nbsp;&nbsp;Inscription / Accès client
              </a>
              <a
                href="/devis/contact/"
                className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orange-600 hover:bg-orange-700"
              >
                <MailIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />&nbsp;&nbsp;Contactez-nous
              </a>
            </div>
          </div>
        </div>
      </div>











      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5 sm:pb-8">
              <div className="flex items-center justify-between">
                <div>
                  <StaticImage 
                        src="../../images/logo__blicko.png"
                        alt="blicko"
                        width={140}
                    />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#3333e6]">
                    <span className="sr-only">Fermer</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6 sm:mt-8">






                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={`
                          ${open ? '' : 'text-opacity-90'}
                          w-full my-3 group inline-flex items-center rounded-md bg-gray-200 px-3 py-2 text-base font-medium text-gray-600 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                      >
                        
                        <ChevronDownIcon
                          className={`${open ? '' : 'text-opacity-70'}
                            ml-2 h-5 w-5 text-gray-600 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                          aria-hidden="true"
                        />&nbsp;
                        <span>Qui sommes nous</span>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                              {presentations.map((item) => (
                                <a
                                  key={item.name}
                                  href={item.href}
                                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                >
                                  <item.icon className="flex-shrink-0 h-6 w-6 text-[#3333e6]" aria-hidden="true" />
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">{item.name}</p>
                                  </div>
                                </a>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>




                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={`
                          ${open ? '' : 'text-opacity-90'}
                          w-full my-3 group inline-flex items-center rounded-md bg-gray-200 px-3 py-2 text-base font-medium text-gray-600 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                      >
                        
                        <ChevronDownIcon
                          className={`${open ? '' : 'text-opacity-70'}
                            ml-2 h-5 w-5 text-gray-600 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                          aria-hidden="true"
                        />&nbsp;
                        <span>Votre situation</span>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                              {situations.map((item) => (
                                <a
                                  key={item.name}
                                  href={item.href}
                                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                >
                                  <item.icon className="flex-shrink-0 h-6 w-6 text-[#3333e6]" aria-hidden="true" />
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">{item.name}</p>
                                  </div>
                                </a>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                


                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={`
                          ${open ? '' : 'text-opacity-90'}
                          w-full my-3 group inline-flex items-center rounded-md bg-gray-200 px-3 py-2 text-base font-medium text-gray-600 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                      >
                        
                        <ChevronDownIcon
                          className={`${open ? '' : 'text-opacity-70'}
                            ml-2 h-5 w-5 text-gray-600 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                          aria-hidden="true"
                        />&nbsp;
                        <span>Trouvez un plombier</span>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                              {plombiers.map((item) => (
                                <a
                                  key={item.name}
                                  href={item.href}
                                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                >
                                  <item.icon className="flex-shrink-0 h-6 w-6 text-[#3333e6]" aria-hidden="true" />
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">{item.name}</p>
                                  </div>
                                </a>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>






              </div>
            </div>




            <div className="py-6 px-5">
              <div className="mt-6">
                <a
                  href="https://app.blicko.fr/login" target="_blank" rel="noreferrer"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#3333e6] hover:bg-blue-700"
                >
                  <UserIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />&nbsp;&nbsp;Inscription / Accès client
                </a>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  <a
                    href="tel:0974371440"
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#3333e6] hover:bg-blue-700"
                  >
                    <PhoneIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />&nbsp;&nbsp;09 74 37 14 40
                  </a>
                </p>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  <a href="/devis/contact/" className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orange-600 hover:bg-orange-700">
                  <MailIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />&nbsp;&nbsp;Contactez-nous
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>





    </Popover>
  )
}